<template>
    <ASelect
        v-model:value="valueState"
        placeholder="Pilih Pelanggan"
        allow-clear
        show-search
        not-found-content="Tidak ditemukan"
        option-filter-prop="label"
        option-label-prop="label"
        @search="onSearch"
        @blur="() => (findText = null)"
        @select="() => (findText = null)"
        v-bind="$attrs"
        :loading="loading"
        :show-arrow="true">
        <ASelectOption v-for="d in lists" :key="d.id" :label="d.name">
            <!-- eslint-disable vue/no-v-html -->
            <span v-html="highlight(d.name)"></span>
            <!--eslint-enable-->
        </ASelectOption>
    </ASelect>
</template>

<script>
import { onMounted, ref, watch } from 'vue'
import apiClient from '@/services/axios'
import { useVModel } from '@/components/useVModel.js'
import { debounce, pickBy } from 'lodash'

export default {
    props: {
        value: {
            type: [Array, Number, Object, String],
            default: null,
        },
    },
    emits: ['update:value'],
    setup(props, { emit }) {
        const lists = ref([])
        const findText = ref(null)
        const loading = ref(false)

        const fetchData = () => {
            loading.value = true
            // by vendor type for master tier
            apiClient.get('/api/filter/vendor-type', { params: pickBy({
                q: findText.value,
            }) })
                .then(({ data }) => {
                    lists.value = data.sort((a, b) =>
                        a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
                    )

                    if (!data.find(item => item.id == props.value)) {
                        // emit('update:value', null)
                    }
                })
                .finally(() => {
                    loading.value = false
                })
        }

        onMounted(() => {
            fetchData()
        })

        watch(() => props.toko, () => {
            fetchData()
        })

        const onSearch = debounce(value => {
            loading.value = true
            findText.value = value
            fetchData()
        }, 300)

        const highlight = value => {
            return value.replace(new RegExp(findText.value, 'gi'), match => {
                return `<span style="background-color: yellow;">${match}</span>`
            })
        }
        return {
            lists,
            valueState: useVModel(props, 'value'),
            findText,
            highlight,
            onSearch,
            loading,
        }
    },
}
</script>