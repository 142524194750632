<template>
    <AModal
        :visible="visible"
        title="Detail Data Tier"
        width="90%"
        @cancel="handleModalCancel"
        :footer="null">

        <div class="row justify-content-end mt-4">
            <div class="col-lg-6 col-md-12"></div>
            <div class="col-lg-6 col-md-12 text-right">
                <AButton
                    class="mr-3"
                    title="Tambah"
                    type="primary"
                    @click="btnForm">
                    <i class="fa fa-plus" aria-hidden="true"></i>
                </AButton>
            </div>
        </div>

        <!-- list table -->
        <div class="table-responsive text-nowrap mt-4">
            <MdTable
                :columns="state.columns"
                :data-source="state.data"
                size="small"
                :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                :pagination="{
                    showSizeChanger: true,
                    pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
                    showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
                    total: state.meta.total,
                    pageSize: state.meta.per_page,
                    current: state.meta.page,
                }"
                @change="handleTableChange"
                :loading="state.loading">
                <template #no="{ index }">
                    <span>
                        {{ (state.meta.page - 1) * state.meta.per_page + 1 + index }}
                    </span>
                </template>
    
                <template #action="{ record }">
                    <ASpace>
                        <ATooltip title="List Benefit">
                            <AButton
                                class="button"
                                size="small"
                                @click="btnBenefitList(record)">
                                <i class="fe fe-list" />
                            </AButton>
                        </ATooltip>
                        <ATooltip title="Edit">
                            <AButton
                                class="button"
                                size="small"
                                @click="btnForm(record)">
                                <i class="fe fe-edit" />
                            </AButton>
                        </ATooltip>
                    </ASpace>
                </template>
            </MdTable>
        </div>

        <!-- form -->
        <TierFormCreateOrUpdate
            v-if="visibleFormModal"
            v-model:visible="visibleFormModal"
            v-model:item="visibleFormItemModal"
            :rule="state.rule"
            @success="fetchDataList"/>

        <!-- benefit -->
        <BenefitList
            v-if="visibleBenefitListModal"
            v-model:visible="visibleBenefitListModal"
            v-model:item="visibleBenefitListItemModal"/>
    </AModal>
</template>

<script>
import { defineComponent, reactive, onMounted, ref } from 'vue'
import { Modal, message } from 'ant-design-vue'
import TierFormCreateOrUpdate from './TierFormCreateOrUpdate'
import BenefitList from './BenefitList'
import apiClient from '@/services/axios'

export default defineComponent({
    components: {
        TierFormCreateOrUpdate,
        BenefitList,
    },
    props: {
        visible: [Boolean],
        item: {
            type: [Object],
            default: () => {},
        },
    },
    emits: ['update:visible'],
    setup(props, { emit }) {
        const state = reactive({
            loading: false,
            data: [],
            meta: {
                per_page: 10,
                page: 1,
                total: 0,
            },
            params: {
                rule: props.item.id,
                page: 1,
                "per-page": 10,
            },
            rule: props.item,
            columns: [
                {
                    title: 'No',
                    slots: { customRender: 'no' },
                },
                {
                    title: 'Nama Tier',
                    dataIndex: 'tier_name',
                },
                {
                    title: 'Level',
                    dataIndex: 'tier_level',
                },
                {
                    title: 'Min Vol',
                    dataIndex: 'min_volume',
                },
                {
                    title: 'Min QTY',
                    dataIndex: 'min_count',
                },
                {
                    title: 'Min Point',
                    dataIndex: 'min_point',
                },
                {
                    title: 'Action',
                    slots: { customRender: 'action' },
                },
            ],
        })

        const handleModalCancel = () => {
            emit('update:visible', false)
            emit('update:item', null)
        }

        const handleTableChange = (page, filters, sorter) => {
            state.params.page = page.current
            state.params["per-page"] = page.pageSize

            fetchDataList()
        }

        const fetchDataList = () => {
            state.loading = true

            apiClient.get(`/api/appreciation-rule-detail/${props.item.id}`, {
                params: state.params,
            }).then(({ data }) => {
                const { items, _meta } = data
                state.data = items
                state.meta.page = _meta.currentPage
                state.meta.per_page = _meta.perPage
                state.meta.total = _meta.totalCount
            }).finally(() => {
                state.loading = false
            })
        }

        // handle form
        const visibleFormModal = ref(false);
        const visibleFormItemModal = ref(null);

        const btnForm = (item = null) => {
            visibleFormModal.value = true
            visibleFormItemModal.value = item
        }

        // handle detail
        const visibleBenefitListModal = ref(false);
        const visibleBenefitListItemModal = ref(null);

        const btnBenefitList = (item = null) => {
            visibleBenefitListModal.value = true
            visibleBenefitListItemModal.value = item
        }

        // handle vue
        onMounted(() => {
            fetchDataList()
        })

        return {
            state,
            handleModalCancel,
            handleTableChange,
            visibleFormModal,
            visibleFormItemModal,
            btnForm,
            visibleBenefitListModal,
            visibleBenefitListItemModal,
            btnBenefitList,
        }
    },
})
</script>